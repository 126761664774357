var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"1300px"}},[_c('v-card',{staticClass:"pl-5 pr-5 pb-5"},[_c('v-card-title',{staticClass:"text-h5 mb-3"},[_vm._v(" Execute Test Run ("+_vm._s(_vm.testScenarioRun.Number)+")"),_c('v-spacer')],1),_c('v-card-subtitle',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.testScenarioRun.Name)+" ("+_vm._s(_vm.testScenarioRun.FullNumber)+") ")]),_c('v-card-subtitle',[_c('h4',[_vm._v("Process")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('router-link',{attrs:{"to":{
                name: 'processDetail',
                params: {
                  id: _vm.testScenarioRunDetail.process.ProcessId,
                  number: _vm.testScenarioRunDetail.process.ProcessNumber,
                },
              }}},[_vm._v(_vm._s(_vm.$appConfig.customer.prefix)+_vm._s(_vm.testScenarioRunDetail.process.ProcessNumber.toString().padStart( 4, '0' ))+" - "+_vm._s(_vm.testScenarioRunDetail.process.ProcessName))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_c('router-link',{attrs:{"to":{
                name: 'processStep',
                params: { id: _vm.testScenarioRunDetail.process.VariantId },
              }}},[_vm._v(_vm._s(_vm.testScenarioRunDetail.process.VariantNumber)+" - "+_vm._s(_vm.testScenarioRunDetail.process.VariantName))]),(
                (_vm.$feature('cloudinaryChartIntegration') && _vm.processUrl) ||
                (_vm.$feature('lucidChartIntegration') && _vm.lucidUrl) ||
                (_vm.$feature('drawIOChartIntegration') && _vm.drawingUrl)
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-10",on:{"click":function($event){_vm.openWindow(
                      _vm.$feature('drawIOChartIntegration') && _vm.drawingUrl
                        ? _vm.drawingUrl
                        : _vm.$feature('lucidChartIntegration') && _vm.lucidUrl
                        ? _vm.lucidUrl
                        : _vm.processUrl
                    )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-image-search-outline ")])]}}],null,false,1370804282)},[_c('span',[_vm._v("Process Flows")])]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ErpApplications?.length > 0),expression:"ErpApplications?.length > 0"}]},[_c('h4',[_vm._v("Application")]),_c('v-list',{staticClass:"list pl-0 ml-0",attrs:{"dense":""}},_vm._l((_vm.ErpApplications),function(app){return _c('v-list-item',{key:app.id},[_c('v-list-item-content',[_c('v-tooltip',{attrs:{"top":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[(app.Link)?_c('a',{attrs:{"href":app.Link,"target":"_blank"}},[(app.Customized)?_c('span',[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(app.Name)+" ")]):_c('span',[_vm._v(_vm._s(app.Name))])])]}}],null,true)},[_vm._v(_vm._s(app.Name))]),_c('v-tooltip',{attrs:{"top":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-subtitle',_vm._g(_vm._b({},'v-list-item-subtitle',attrs,false),on),[_vm._v(_vm._s(app.Filter)+" ")])]}}],null,true)},[_vm._v(_vm._s(app.Filter))])],1)],1)}),1)],1)])],1)],1),_c('v-card-text',[_c('v-row',[(_vm.testScenarioRun.Description)?_c('v-col',{staticClass:"text-pre-wrap"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.testScenarioRun.Description)}})]):_vm._e()],1),_c('v-row',[(_vm.testScenarioRunDetail.TestInput)?_c('v-col',{staticClass:"headline mb-5"},[_vm._v("Test Input: "+_vm._s(_vm.testScenarioRunDetail.TestInput)+" ")]):_vm._e(),_c('v-col',{attrs:{"text-align":"right","cols":"12","md":"2"}},[_c('v-btn',{attrs:{"loading":_vm.validating,"disabled":!_vm.testScenarioRunSteps.filter((f) => f.Done == false)?.length >
                0},on:{"click":_vm.validateAll}},[_vm._v("Validate All")])],1)],1),(_vm.testScenarioRunSteps)?_c('v-data-table',{staticClass:"text-pre-wrap",attrs:{"headers":_vm.headers,"items":_vm.testScenarioRunSteps,"sort-by":"Number","disable-pagination":"","hide-default-footer":"","show-expand":"","loading":_vm.isFindTestScenarioRunStepsPending,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:`item.color`,fn:function({ item }){return [(item.GroupIteration > 0)?_c('v-icon',{attrs:{"id":'expandable-' + item.id,"small":"","color":item.group?.Color}},[_vm._v("mdi-circle-multiple")]):_vm._e(),(item.GroupIteration > 0)?_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.GroupIteration + 1))]):_vm._e(),(item.group && item.GroupIteration == 0)?_c('v-icon',{attrs:{"color":item.group?.Color}},[_vm._v("mdi-circle")]):_vm._e()]}},{key:`item.Fields`,fn:function(i = { item: _vm.item }){return [_c('div',[(
                  _vm.testScenarioRunFieldValues.find(
                    (f) => f.TestScenarioRunStepsId == i.item.id
                  )
                )?_c('v-list',{staticClass:"ma-0",attrs:{"dense":""}},_vm._l((_vm.listField(i.item.id)),function(field){return _c('v-list-item',{key:field.id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(field)+" ")]),_vm._l((_vm.listFieldValues(i.item.id, field)),function(value){return _c('v-list-item-subtitle',{key:value.id},[_vm._v(" "+_vm._s(value.Name)+" "),(value.Code)?_c('span',[_vm._v("("+_vm._s(value.Code)+")")]):_vm._e()])})],2)],1)}),1):_vm._e()],1)]}},{key:`item.TestRemark`,fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.TestRemark)}})]}},{key:`item.TestResult`,fn:function({ item }){return [_c('div',[(item.Done && item.blockedBugs == 0)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.validateTest(item)}}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.blockedBugs > 0)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"red","small":""}},[_vm._v("mdi-close-thick")]):_vm._e(),(!item.Done && item.blockedBugs == 0)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.validateTest(item)}}},[_vm._v("mdi-checkbox-blank-outline ")]):_vm._e()],1)]}},{key:`item.Issue`,fn:function({ item }){return [_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":item.blockedBugs > 0
                  ? 'red'
                  : item.openBugs > 0
                  ? 'orange'
                  : item.issueStatus?.length > 0
                  ? 'green'
                  : 'primary'},on:{"click":function($event){return _vm.clickAddIssue(item)}}},[_vm._v(" mdi-login-variant ")])]}},{key:"expanded-item",fn:function({ item }){return [(item.process_step?.Description)?_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0"},[_c('span',{staticClass:"ma-3 ql-editor",domProps:{"innerHTML":_vm._s(item.process_step.Description)}})])],1),_c('v-row',[_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"align":"right"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggle(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s('mdi-swap-horizontal'))])],1),(_vm.show[item.id])?_c('Updated',{staticClass:"mr-5 my-2",attrs:{"updateDate":item.updatedAt,"userId":item.ModifiedById,"table":"process_step","id":item.id}}):_vm._e()],1)],1)],1):_vm._e()]}}],null,true)}):_vm._e()],1),_c('v-card-actions',[_c('v-text-field',{attrs:{"label":"Test Output Reference*","outlined":"","dense":"","hide-details":"","rules":[_vm.rules.required]},on:{"blur":_vm.saveOutputReference},model:{value:(_vm.testScenarioRunDetail.TestOutput),callback:function ($$v) {_vm.$set(_vm.testScenarioRunDetail, "TestOutput", $$v)},expression:"testScenarioRunDetail.TestOutput"}}),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!this.testScenarioRunDetail.TestOutput && _vm.StepValidated,"color":"primary","loading":_vm.closing || _vm.validating},on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")])],1),(_vm.issueDialog)?_c('v-dialog',{attrs:{"hide-overlay":"","width":"90vw","height":"auto"},model:{value:(_vm.issueDialog),callback:function ($$v) {_vm.issueDialog=$$v},expression:"issueDialog"}},[_c('v-card',{staticClass:"pa-5",attrs:{"height":"90vh"}},[(_vm.issueDialog)?_c('IssueList',{attrs:{"testScenarioRunStep":_vm.currentStep},model:{value:(_vm.issueDialog),callback:function ($$v) {_vm.issueDialog=$$v},expression:"issueDialog"}}):_vm._e()],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }