<template>
  <div>
    <v-switch
      v-model="showAll"
      label="Show All"
      hide-details
      class="mb-5 ml-4"
    ></v-switch>
    <v-data-table
      v-if="filteredStepsOnSelection"
      :headers="headers"
      :items="filteredStepsOnSelection"
      :items-per-page="20"
      item-key="Number"
      class="elevation-1"
      disable-pagination
      show-select
      show-expand
      single-expand
      @item-selected="clickedRow"
      @toggle-select-all="clickedAll"
      v-model="selected"
      dense
      :hide-default-footer="true"
      :loading="isPending"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`header.data-table-select`]>Selected</template>
      <template v-slot:[`item.color`]="{ item }">
        <span
          v-for="color in group.find((f) => f.id == item.GroupId)?.allColors"
          :key="color"
        >
          <v-icon
            v-if="item.group?.Loop"
            small
            :color="color"
            @click="addLoop(item)"
            >mdi-sync-circle</v-icon
          >
          <v-icon v-else small :color="color">mdi-circle</v-icon>
          {{ item.GroupIteration }}
        </span>
      </template>
      <template v-slot:[`item.Fields`]="{ item }">
        <TestScenarioStepFields
          v-if="item.id"
          :testScenarioLineStep="item"
          :disabled="!item.id"
        />
      </template>
      <template v-slot:[`item.TestRemark`]="{ item }">
        <div class="testRemark" @click="clickEditTestRemark(item)">
          <span v-html="truncateHTML(item.TestRemark, 40)" />
          <v-icon
            class="icon-top-right"
            small
            :disabled="!item.id"
            @click="clickEditTestRemark(item)"
            >mdi-pencil</v-icon
          >
        </div>
      </template>
      <template v-slot:[`item.status.Testable`]="{ item }">
        <v-icon v-if="item.Testable" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length" v-if="item?.Description">
          <v-row
            ><v-col class="mb-0 pb-0"
              ><span
                class="ma-3 ql-editor"
                v-html="item?.Description"
              ></span></v-col
          ></v-row>
        </td>
      </template>
    </v-data-table>
    <Modal
      title="Edit test Remark"
      width="1100px"
      v-if="showTestRemarkEditor"
      @cancel="showTestRemarkEditor = false"
      @confirm="
        saveLineStep(currentItem);
        showTestRemarkEditor = false;
      "
    >
      <RichEditor
        v-model="currentItem.TestRemark"
        :disabled="!$can('update', currentItem, 'TestRemark')"
      ></RichEditor>
    </Modal>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import { truncateHTML } from '@/utils/Utilities';
import TestScenarioStepFields from '@/components/TestScenarios/TestScenario/TestScenarioStepFields.vue';
import Modal from '@/components/general/Modal.vue';
import RichEditor from '@/components/general/RichEditor.vue';
import { handleErrorResponse } from '@/utils/MessageHandler';

export default {
  props: {
    variant: {
      type: Object,
      required: true,
    },
    stepsToAdd: {
      type: Array,
      required: false,
    },
    testScenarioLine: {
      type: Object,
      required: true,
    },
    // addModus: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  components: { TestScenarioStepFields, Modal, RichEditor },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line-step',
    }),
    makeFindMixin({
      service: 'process-step',
      watch: ['variant.id'],
    }),
    makeFindMixin({
      service: 'group',
      watch: ['variant.id'],
    }),
  ],
  watch: {
    // processStep() {
    //   this.updateFilteredProcesses();
    //   // this.getLineSteps();
    // },
    // showAll() {
    //   this.updateFilteredProcesses();
    //   // this.getLineSteps();
    // },
    isPending: function () {
      // this.initialize();
      if (this.isPending == false) {
        this.getLineSteps();
      }
    },
    // 'testScenarioLine.id': function () {
    //   // this.initialize();
    //   this.getLineSteps();
    // },
  },
  data() {
    return {
      showAll: false,
      lineSteps: [],
      selected: [],
      currentItem: null,
      showTestRemarkEditor: false,
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'color',
          width: '80px',
        },
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'ProcessNumber',
          width: '50px',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        {
          text: 'Fields',
          align: 'start',
          sortable: false,
          value: 'Fields',
          width: '250px',
        },
        {
          text: 'Test Remark',
          align: 'start',
          sortable: false,
          value: 'TestRemark',
        },
        {
          text: 'Testable',
          align: 'center',
          sortable: false,
          value: 'status.Testable',
        },
        {
          text: '',
          align: 'end',
          value: 'data-table-expand',
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  computed: {
    isPending() {
      return (
        this.isFindProcessStepPending || this.isFindTestScenarioLineStepPending
      );
    },
    testScenarioLineStepParams() {
      if (this.testScenarioLine.id) {
        return { query: { TestScenarioLineId: this.testScenarioLine.id } };
      } else return { query: { id: -1 } };
    },
    processStepParams() {
      return { query: { VariantId: this.variant.id } };
    },
    groupParams() {
      return { query: { VariantId: this.variant.id } };
    },
    filteredStepsOnSelection() {
      if (this.showAll) {
        return this.lineSteps;
      } else {
        return this.lineSteps.filter((f) => f.Selected == true);
      }
    },
    // filteredProcesses() {
    //   if (!this.isPending) {
    //     this.updateFilteredProcesses();
    //   }
    //   return this.lastFilteredProcesses;
    // },
  },
  methods: {
    truncateHTML,
    async getLineSteps() {
      const updatedTestScenarioLineSteps = this.testScenarioLineStep.map(
        (step) => {
          let processStep = this.processStep.find(
            (f) => f.id == step.ProcessStepId
          );
          return {
            ...step,
            Selected: true,
            ProcessNumber: processStep?.Number,
            Name: processStep?.Name,
            Description: processStep?.Description,
            // GroupId: processStep?.GroupId ?? processStep?.Number,
            NewGroupId: processStep?.GroupId ?? processStep?.Number,
            group: processStep?.group,
            Testable: processStep?.status?.Testable,
          };
        }
      );

      // add Missing processSteps
      const usedProcessStepIds = this.testScenarioLineStep.map(
        (step) => step.ProcessStepId
      );
      const unusedProcessSteps = this.processStep.filter(
        (processStep) => !usedProcessStepIds.includes(processStep.id)
      );

      for (const step of unusedProcessSteps) {
        const { TestScenarioLineStep } = this.$FeathersVuex.api;
        let newStep = new TestScenarioLineStep();
        newStep.TestScenarioLineId = this.testScenarioLine.id;
        newStep.ProcessStepId = step.id;
        newStep.Selected = false;
        newStep.Name = step.Name;
        newStep.Description = step.Description;
        newStep.GroupId = step.GroupId;
        newStep.NewGroupId = step.GroupId != null ? step.GroupId : step?.Number;
        newStep.group = step.group;
        newStep.Testable = step.status?.Testable;
        newStep.ProcessNumber = step?.Number;
        updatedTestScenarioLineSteps.push(newStep);
      }

      // Step 1: Group the data by 'Group' and store numbers

      const grouped = updatedTestScenarioLineSteps
        .sort(
          (a, b) =>
            a.NewGroupId - b.NewGroupId || a.ProcessNumber - b.ProcessNumber
        )
        .reduce((acc, entry) => {
          // Use GroupId as the main grouping key
          let groupkey = entry.NewGroupId;

          // If the group doesn't exist, initialize it
          if (!acc[groupkey]) {
            acc[groupkey] = {}; // Initialize the group
            acc[groupkey].lowestProcessNumber = entry.ProcessNumber; // Store the lowest ProcessNumber for this group
            acc[groupkey].entries = {}; // Initialize a sub-object for ProcessNumbers
          }

          // Use the current ProcessNumber as a key in the sub-group
          if (!acc[groupkey].entries[entry.ProcessNumber]) {
            acc[groupkey].entries[entry.ProcessNumber] = [];
          }

          // Add the entry under the current ProcessNumber
          acc[groupkey].entries[entry.ProcessNumber].push(entry);

          return acc;
        }, {});

      // Now flatten the object and use the lowest ProcessNumber as the top-level key
      const groupedData = Object.keys(grouped).reduce((acc, groupkey) => {
        const group = grouped[groupkey];
        const lowestProcessNumber = group.lowestProcessNumber;

        // Set the group using the lowest ProcessNumber as the key
        acc[lowestProcessNumber] = group.entries;

        return acc;
      }, {});

      // Step 2: Reorder the data by alternating Iterations within each group
      const reorderedData = [];

      Object.keys(groupedData).forEach((group) => {
        const numberMap = groupedData[group];

        // Get the maximum iterations for all numbers in the group
        let maxIterations = Math.max(
          ...Object.values(numberMap).map((arr) => arr.length)
        );

        // Loop through the iterations
        for (let i = 0; i < maxIterations; i++) {
          Object.keys(numberMap).forEach((number) => {
            // Push the item with the current iteration if it exists
            if (numberMap[number][i] !== undefined) {
              reorderedData.push(numberMap[number][i]); // Add the whole object including other fields
            }
          });
        }
      });

      for (const [index, line] of reorderedData
        .filter((f) => f.Selected == true)
        .entries()) {
        line.Number = index + 1;
      }

      await this.renumber(reorderedData.filter((f) => f.Selected == true));

      this.lineSteps = reorderedData;

      this.selected = this.lineSteps.filter((f) => f.Selected == true);
    },
    clickEditTestRemark(item) {
      if (item.id) {
        this.currentItem = item;
        this.showTestRemarkEditor = true;
      }
    },

    async addLoop(item) {
      const { TestScenarioLineStep } = this.$FeathersVuex.api;
      let maxIteration = this.maxIterationperGroup(item.GroupId);
      for (const loopStep of this.lineSteps.filter(
        (f) => f.GroupId == item.GroupId && f.GroupIteration == 0
      )) {
        let newStep = new TestScenarioLineStep();
        Object.assign(newStep, loopStep); //
        newStep.GroupIteration = maxIteration + 1;

        newStep.id = null;
        try {
          await newStep.save();
          // await TestScenarioLineStep.find();
        } catch (error) {
          handleErrorResponse(error);
        }
      }

      await TestScenarioLineStep.find({
        query: { TestScenarioLineId: this.testScenarioLine.id },
      });
      this.getLineSteps();
    },
    maxIterationperGroup(groupId) {
      return this.testScenarioLineStep
        .filter((f) => f.GroupId == groupId)
        .reduce((max, obj) => Math.max(max, obj.GroupIteration), 0);
    },
    async saveLineStep(item) {
      try {
        await feathersClient.service('test-scenario-line-step').patch(item.id, {
          TestRemark: item.TestRemark,
        });
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    async renumber(orderedList) {
      // Renumber
      for (const step of this.testScenarioLineStep
        // .sort((a, b) => a.process_step.Number - b.process_step.Number)
        .filter((f) => f.TestScenarioLineId == this.testScenarioLine.id)) {
        step.Number =
          orderedList?.find(
            (f) =>
              f.ProcessNumber == step.process_step.Number &&
              f.GroupIteration == step.GroupIteration
          )?.Number ?? 9999;

        try {
          await step.save();
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    },
    async clickedRow(value) {
      this.saving = true;

      if (this.testScenarioLine.id) {
        if (value.value) {
          // Add line to group step
          try {
            const saved = await feathersClient
              .service('test-scenario-line-step')
              .create({
                TestScenarioLineId: this.testScenarioLine.id,
                ProcessStepId: value.item.ProcessStepId,
                TestRemark: value.item.TestRemark,
                GroupId: value.item.GroupId,
              });
            const { TestScenarioLineStep } = this.$FeathersVuex.api;
            await TestScenarioLineStep.find({ query: { id: saved.id } });
          } catch (error) {
            handleErrorResponse(error);
          }
        } else {
          let Steps = this.testScenarioLineStep.find(
            (f) =>
              f.TestScenarioLineId == value.item.TestScenarioLineId &&
              f.ProcessStepId == value.item.ProcessStepId &&
              // f.GroupId == value.item.GroupId &&
              f.GroupIteration == value.item.GroupIteration
          );
          value.item.TestScenarioLineStepLinked = false;
          value.item.TestScenarioLineStepId = null;
          if (Steps) {
            try {
              await feathersClient
                .service('test-scenario-line-step')
                .remove(Steps.id);
            } catch (error) {
              handleErrorResponse(error);
            }
          }
        }

        this.saving = false;
      }

      await this.getLineSteps();
    },
    async clickedAll(value) {
      this.selectPending = true;
      for (const v of value.items) {
        let item = {};
        item.item = v;
        item.value = value.value;
        await this.clickedRow(item);
      }
      // await this.getLineSteps();
      this.selectPending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.testRemark {
  width: 200px;
  border: 1px solid lightgrey;
  padding: 5px;
  margin: 5px;
  min-height: 40px;
  position: relative;
}
.testRemark .icon-top-right {
  position: absolute;
  right: 0px; /* Adjust the value to fine-tune the position */
  top: 50%; /* Start at the middle of the box */
  transform: translateY(-50%); /* Adjust to perfectly center vertically */
  border-radius: 50%; /* Optional: Makes the icon background circular */
  padding: 5px; /* Optional: Adds some space around the icon */
}
</style>
